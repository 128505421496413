
import Icon from '@/components/UntitledUI/Icon.vue'
import Dot from '@/components/UntitledUI/Dot.vue'

export default {
    name: 'Badge',
    components: {
        Icon,
        Dot
    },
    props: {
        leadingDot: {
            type: Boolean,
            default: false
        },
        leadingIcon: {
            type: String,
            default: null
        },
        trailingIcon: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md', 'lg'].includes(val)
        },
        theme: {
            type: String,
            default: 'color',
            validator: val => ['color', 'color-outline', 'modern'].includes(val)
        },
        color: {
            type: String,
            default: 'gray',
            validator: val => ['gray', 'brand', 'brand-light', 'error', 'warning', 'success'].includes(val)
        },
        isSquare: {
            type: Boolean,
            default: false
        },
        onlyIcon: {
            type: Boolean,
            default: false
        },
        nowrap: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes () {
            const classes = []

            // Radius
            if (this.isSquare) {
                switch (this.size) {
                case 'sm':
                case 'md':
                    classes.push('rounded-md')
                    break
                case 'lg':
                    classes.push('rounded-lg')
                    break
                }
            } else {
                classes.push('rounded-full')
            }
            // Paddings
            if (this.onlyIcon){
                classes.push({
                    sm: 'p-[0.3125rem]',
                    md: 'p-[0.375rem]',
                    lg: 'p-[0.5rem]'
                }[this.size])
            }

            if (!this.onlyIcon) {
                classes.push({
                    sm: 'py-[0.0625rem]',
                    md: 'py-[0.0625rem]',
                    lg: 'py-[0.1875rem]'
                }[this.size])

                if (!this.leadingIcon && !this.trailingIcon && !this.leadingDot) {
                    classes.push({
                        sm: 'px-[0.4375rem]',
                        md: 'px-[0.5625rem]',
                        lg: 'px-[0.6875rem]'
                    }[this.size])
                } else {
                    classes.push({
                        sm: this.leadingIcon || this.leadingDot ? 'pr-[0.3125rem]' : 'pr-[0.4375rem]',
                        md: this.leadingIcon || this.leadingDot ? 'pr-[0.4375rem]' : 'pr-[0.5625rem]',
                        lg: this.leadingIcon || this.leadingDot ? 'pr-[0.5625rem]' : 'pr-[0.6875rem]'
                    }[this.size])

                    classes.push({
                        sm: this.trailingIcon ? 'pl-[0.3125rem]' : 'pl-[0.4375rem]',
                        md: this.trailingIcon ? 'pl-[0.4375rem]' : 'pl-[0.5625rem]',
                        lg: this.trailingIcon ? 'pl-[0.5625rem]' : 'pl-[0.6875rem]'
                    }[this.size])
                }
            }

            switch (this.size) {
            case 'sm':
                classes.push('text-xs')
                break
            case 'md':
            case 'lg':
                classes.push('text-sm')
                break
            }

            // Colors
            if (this.theme === 'color') {
                classes.push({
                    gray: 'bg-gray-50 border-gray-200 text-gray-700',
                    brand: 'bg-brand-50 border-brand-200 text-brand-500',
                    'brand-light': 'bg-brand-25 border-brand-100 text-brand-500',
                    error: 'bg-error-50 border-error-200 text-error-500',
                    warning: 'bg-warning-50 border-warning-200 text-warning-700',
                    success: 'bg-success-50 border-success-200 text-success-700'
                }[this.color])
            }
            if (this.theme === 'color-outline') {
                classes.push({
                    gray: 'border-gray-600 text-gray-700',
                    brand: 'border-brand-600 text-brand-500',
                    error: 'border-error-600 text-error-500',
                    warning: 'border-warning-600 text-warning-700',
                    success: 'border-success-600 text-success-700'
                }[this.color])
            }

            if (this.theme === 'modern') {
                classes.push('shadow-sm bg-white text-gray-700 border-gray-300')
            }

            // nowrap
            if (this.nowrap) {
                classes.push('whitespace-nowrap')
            }

            return classes.join(' ')
        }
    }
}

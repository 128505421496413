
import Icon from '@/components/UntitledUI/Icon.vue'
import intercom from '~/Utils/Intercom'

export default {
    name: 'AvatarWithOptions',
    components: {
        Icon
    },
    data () {
        return {
            openDropDown: false
        }
    },
    methods: {
        async logout () {
            this.openDropDown = false
            this.$store.commit('files/resetAll')
            this.$store.dispatch('notifications/destroy')
            this.$store.commit('GettingStarted/setIsAllStepsDone', false)
            intercom.shutdown()
            await this.$auth.logout()
            this.$gtm.push({
                event: 'userData',
                user_id: null
            })
            this.$router.push(this.localePath('/Login'))
        }
    }
}

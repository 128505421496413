
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'Button',
    components: {
        Icon
    },
    props: {
        leadingIcon: {
            type: String,
            default: () => null
        },
        trailingIcon: {
            type: String,
            default: () => null
        },
        text: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: () => 'sm',
            validator: val => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(val)
        },
        rounded: {
            type: String,
            default: () => 'lg',
            validator: val => ['lg', 'full'].includes(val)
        },
        theme: {
            type: String,
            default: 'primary',
            validator: val => [
                'primary',
                'brand',
                'secondary-gray',
                'secondary-color',
                'secondary-color-full',
                'tertiary-gray',
                'tertiary-2gray',
                'tertiary-color',
                'link-gray',
                'link-2gray',
                'link-color',
                'primary-destructive',
                'secondary-destructive',
                'tertiary-destructive',
                'link-destructive'
            ].includes(val)
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        onlyIcon: {
            type: Boolean,
            default: false
        },
        submit: {
            type: Boolean,
            default: false
        },
        tabIndex: {
            type: [String, Number],
            default: 0
        }
    },
    computed: {
        cSize () {
            if (this.$parent.$options.name === 'InputField') {
                if (this.onlyIcon) {
                    return this.$parent.size === 'sm' ? 'md' : 'lg'
                }
                return this.$parent.size
            }

            return this.size
        },
        getClasses () {
            const links = ['link-gray', 'link-color', 'link-destructive']
            const classes = []
            if (this.$parent.$options.name === 'InputField') {
                classes.push({
                    lg: 'rtl:first:rounded-r-lg rtl:last:rounded-l-lg ltr:first:rounded-l-lg ltr:last:rounded-r-lg',
                    full: 'rtl:first:rounded-r-full rtl:last:rounded-l-full ltr:first:rounded-l-full ltr:last:rounded-r-full'
                }[this.rounded])
                classes.push('border-x-transparent')
            } else {
                classes.push(classes.push({
                    lg: 'rounded-lg',
                    full: 'rounded-full'
                }[this.rounded]))
            }
            // Paddings
            if (!links.includes(this.theme)) {
                classes.push({
                    sm: this.onlyIcon ? 'p-[0.4375rem]' : 'px-[0.6875rem] py-[0.4375rem]',
                    md: this.onlyIcon ? 'p-[0.5625rem]' : 'px-[0.8125rem] py-[0.5625rem]',
                    lg: this.onlyIcon ? 'p-[0.6875rem]' : 'px-[0.9375rem] py-[0.5625rem]',
                    xl: this.onlyIcon ? 'p-[0.8125rem]' : 'px-[1.0625rem] py-[0.6875rem]',
                    '2xl': this.onlyIcon ? 'p-[0.9375rem]' : 'px-[1.3125rem] py-[0.9375rem]'
                }[this.cSize])
            }

            // Font Weight
            if (!this.onlyIcon) {
                classes.push('font-semibold')
            }
            // Font Size
            if (!this.onlyIcon) {
                switch (this.cSize) {
                case 'xs':
                    classes.push('text-xs')
                    break
                case 'sm':
                case 'md':
                    classes.push('text-sm')
                    break
                case 'lg':
                case 'xl':
                    classes.push('text-md')
                    break
                case '2xl':
                    classes.push('text-lg')
                    break
                }
            }
            // Gaps
            if (links.includes(this.theme)) {
                classes.push({
                    sm: 'gap-1.5',
                    md: 'gap-1.5',
                    lg: 'gap-2',
                    xl: 'gap-2',
                    '2xl': 'gap-3'
                }[this.cSize])
            } else {
                classes.push({
                    sm: 'gap-1',
                    md: 'gap-1',
                    lg: 'gap-1.5',
                    xl: 'gap-1.5',
                    '2xl': 'gap-2.5'
                }[this.size])
            }

            // Colors
            classes.push({
                'primary': 'shadow-sm text-white bg-brand-600 border border-brand-600 hover:bg-brand-700 hover:border-brand-700 focus:ring-4 focus:ring-brand-200 disabled:text-gray-400 disabled:bg-gray-100 disabled:border-gray-200 disabled:ring-0',
                'brand': 'shadow-sm text-white bg-brand-500 border border-brand-500 hover:bg-brand-600 hover:border-brand-600 focus:ring-4 focus:ring-brand-100 disabled:text-white disabled:bg-gray-400 disabled:border-gray-200 disabled:ring-0',
                'secondary-gray': 'shadow-sm border rtl:last:border-l-gray-300 ltr:last:border-r-gray-300 rtl:first:border-r-gray-300 ltr:first:border-l-gray-300 focus:border-x-gray-300 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-800 focus:ring focus:ring-gray-200 disabled:ring-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-white',
                'secondary-color': 'shadow-sm border border-brand-300 text-brand-500 hover:bg-brand-50 hover:text-brand-800 focus:ring focus:ring-brand-200 disabled:ring-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-white',
                'tertiary-gray': 'text-gray-700 hover:text-gray-800 hover:bg-gray-50 disabled:hover:bg-white disabled:text-gray-400',
                'tertiary-color': 'text-brand-500 hover:text-brand-800 hover:bg-brand-50 disabled:hover:bg-white disabled:text-gray-400',
                'link-gray': 'text-gray-600 hover:text-gray-700 focus:text-gray-600 disabled:text-gray-400',
                'link-2gray': 'text-gray-500 hover:text-gray-700 focus:text-gray-600 disabled:text-gray-400',
                'link-color': 'text-brand-600 hover:text-brand-500 focus:text-brand-600 disabled:text-gray-400',
                'primary-destructive': 'shadow-sm text-white bg-red-600 border border-red-600 hover:bg-red-700 hover:border-red-700 focus:ring-4 focus:ring-red-200 disabled:text-gray-400 disabled:bg-gray-100 disabled:border-gray-200 disabled:ring-0',
                'secondary-destructive': 'shadow-sm border border-red-300 text-red-700 hover:bg-red-50 hover:text-red-800 focus:ring focus:ring-red-200 disabled:ring-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-white',
                'tertiary-destructive': 'text-red-700 hover:text-red-800 hover:bg-red-50 disabled:hover:bg-white disabled:text-gray-400',
                'link-destructive': 'text-red-600 hover:text-red-700 focus:text-red-600 disabled:text-gray-400'
            }[this.theme])

            return classes.join(' ')
        }
    }
}

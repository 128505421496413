
import Icon from '@/components/UntitledUI/Icon.vue'
import Tippy from '@/components/common/Tippy.vue'
import Dot from '@/components/UntitledUI/Dot.vue'
import AccountSwitcher from '@/components/Layout/AccountSwitcher.vue'

export default {
    name: 'SideBar',
    components: { AccountSwitcher, Dot, Icon, Tippy },
    props: {
        isExpanded: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            expanded: this.isExpanded,
            sideLinks: [
                {
                    to: ['/GettingStarted', '/Search'].map(p => this.localePath(p)),
                    icon: 'home-02',
                    label: this.$t('home page')
                },
                {
                    rule: '',
                    to: this.localePath('/Contracts'),
                    icon: 'documents',
                    label: this.$t('contracts page'),
                    feature: 'contracts'
                },
                {
                    to: this.localePath('/HighlightsAndNotes'),
                    icon: 'marker',
                    label: this.$t('highlights and notes page')
                },
                {
                    to: this.localePath('/Folders'),
                    icon: 'opened-folder',
                    label: this.$t('my folders page')
                },
                {
                    to: this.localePath('/AI/Chat'),
                    icon: 'AI',
                    label: this.$t('ai gpt page'),
                    feature: 'gpt'
                },
                {
                    rule: '',
                    to: this.localePath('/Workers'),
                    icon: 'users-01',
                    label: this.$t('workers page'),
                    feature: 'workers'
                },
                {
                    rule: '',
                    to: this.localePath('/Subscriptions'),
                    icon: 'clip-board',
                    label: this.$t('subscriptions page')
                },
                {
                    rule: '',
                    to: this.localePath('/Settings'),
                    icon: 'setting',
                    label: this.$t('settings page')
                }
            ]
        }
    },
    computed: {
        links () {
            return this.sideLinks.filter(l => this.$can(l.feature))
        }
    },
    watch: {
        isExpanded (val) {
            this.expanded = val
        }
    },
    methods: {
        expand () {
            this.expanded = true
        },
        shrink () {
            this.expanded = false
        },
        toggle () {
            this.expanded = !this.expanded
        }
    }
}

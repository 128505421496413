
import debounce from 'lodash/debounce'
import Tippy from '@/components/common/Tippy.vue'
import * as Sentry from '@sentry/vue'
import ArticleViewer from './ArticleViewer.vue'

export default {
    components: { ArticleViewer, Tippy },

    data () {
        return {
            helpCenter: {
                minimize: true,
                show: false
            },
            sections: [],
            opened: [],
            openedGroup: [],
            query: null,
            searchArticles: [],
            showSearchResult: true,
            debouncedInput: debounce(this.search, 600),
            article: null,
            group: null,
            section: null,
            video: null,
            ratingData: {
                thumbs: null,
                comment: null
            },
            showArticle: false,
            loadingSearchResult: false
        }
    },

    computed: {
        hasSearchArticles () {
            return this.searchArticles.length !== 0
        }
    },

    methods: {
        async fetchArticle (id) {
            try {
                const { data } = await this.$axios.get(`v1/help-center-article/${id}`)
                this.article = data,
                    this.group = data?.group,
                    this.section = data?.section,
                    this.video = data?.video,
                    this.ratingData = data?.rating

                this.showArticle = true
                this.query = ''
                this.searchArticles = []

                this.scrollToTop()
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        async fetchSections () {
            try {
                const { data } = await this.$axios.get('v1/help-center-sections')
                this.sections = data
                this.opened = this.sections.map(section => section.id)
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        showHelpCenter () {
            this.helpCenter.show = true
            this.fetchSections()
        },

        isOpen (id) {
            return this.opened.includes(id)
        },

        isGroupOpen (id) {
            return this.openedGroup.includes(id)
        },

        async search () {
            if (this.query === '') {
                this.loadingSearchResult = false
                this.searchArticles = []
                return
            }

            this.loadingSearchResult = true

            try {
                const { data } = await this.$axios.get(`v1/help-center-search?q=${this.query}`)
                this.searchArticles = data
                this.loadingSearchResult = false
            } catch (error) {
                Sentry.captureException(error)
            }
        },

        toggleSection (id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter(i => i !== id)
                return
            }

            this.opened.push(id)
            this.scrollToEnd(`section_${id}`)
        },

        toggleGroup (id) {
            if (this.openedGroup.includes(id)) {
                this.openedGroup = this.openedGroup.filter(i => i !== id)
                return
            }

            this.openedGroup.push(id)
            this.scrollToEnd(`group_${id}`)
        },

        focusInput () {
            this.showSearchResult = true
        },

        scrollToTop () {
            const viewer = this.$refs.viewer
            const sections = this.$refs.sections

            if (viewer) {
                viewer.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }

            if (sections) {
                sections.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        },

        scrollToEnd (component = 'main') {
            setTimeout(() => {
                this.$refs[component][0]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end'
                })
            }, 200)
        }
    }
}

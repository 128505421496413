import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=66b65174&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBarNew: require('/var/www/qanoniah.com/components/main/HeaderBarNew.vue').default,FooterBar: require('/var/www/qanoniah.com/components/main/FooterBar.vue').default})

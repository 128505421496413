import { render, staticRenderFns } from "./MiniHelpCenter.vue?vue&type=template&id=63b20078&scoped=true&"
import script from "./MiniHelpCenter.vue?vue&type=script&lang=js&"
export * from "./MiniHelpCenter.vue?vue&type=script&lang=js&"
import style0 from "./MiniHelpCenter.vue?vue&type=style&index=0&id=63b20078&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b20078",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tippy: require('/var/www/qanoniah.com/components/common/Tippy.vue').default})

export default function ({ $auth, redirect, app }) {
    if (!$auth.user.is_tenant_owner && !$auth.user.is_subscribe) {
        return redirect(app.localePath('/Expired'))
    }

    if (!$auth.user.has_subscriptions) {
        return redirect(app.localePath('/Packages'))
    }

    if (!$auth.user.is_subscribe) {
        return redirect(app.localePath('/Subscriptions'))
    }
}

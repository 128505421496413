export const state = () => ({
    changelogsPreview: null,
    userTypes: null
})

export const mutations = {
    setChangelogsPreview (state, payload) {
        state.changelogsPreview = payload
    },

    setUserTypes (state, payload) {
        state.userTypes = payload
    }
}

export const getters = {
    getChangelogsPreview: (state) => {
        return state.changelogsPreview
    },

    getUserTypes: (state) => {
        return state.userTypes
    }
}
